import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: inherit;
  }
  html {
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .background{
    color: #999999;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999;
  }
  #wrapper{
    position: relative;
}

.content{
    color: #FFFFFF;
    font-size: 26px;
    font-weight: bold;
    text-shadow: -1px -1px 1px #000, 1px 1px 1px #000;
    position: relative;
    z-index: 100;
}

html, body {
  text-align: center;
  width: 100%;
  height: 100%
  margin:0;
  padding:0;
  background: #000000;
  color: #666666;
  line-height: 1.25em;
}
#outer {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: visible;
}
#canvasContainer {
  position: relative;
  width: 100vw;
  height: 100vh;
}
#mainCanvas {
}
canvas {
  position: absolute;
}
content {
  position: relative;
}
img {
  max-width: 100%;
  max-height: 100%;
}

a {
  color: #00CBCB;
  text-decoration: none;
  font-weight: bold;
}
a:hover {
  color: #FFFFFF;
}
#output {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.75em;
  margin-top: 4px;
}
/*#more-experiments{
color: #666666;
  font-weight: normal;
}*/

@media screen and (max-width: 3840){

  #canvasContainer {
    width: 100vw;
    height: 100vh;
  }
  #output {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1em;
    line-height: 1.75em;
  }

}

`

export default GlobalStyle
