import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'
import ImageCard from '../components/ImageCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/MozillaPortraitsquare.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`
const HeroWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: center;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
  line-height: 1;
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
  line-height: 1;
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
  <div id="background">
    <div id="outer">
        <div id="canvasContainer">
          <canvas id="mainCanvas">
          </canvas>
          <div id="content">
          <Layout />
    <Parallax pages={5}>
      <Hero>
        <BigTitle>
          Colin<br /> <br /> <br /> <br />Kushniruk.
        </BigTitle>
        <br />
        <Subtitle>Games Artist & C++ Games Programmer</Subtitle>
        <HeroWrapper>
          <ImageCard
            title="Novus Imperium"
            path="https://www.artstation.com/artwork/1nb8Ae"
            cover="https://cdnb.artstation.com/p/assets/images/images/015/409/251/large/colin-kushniruk-adp-strikerspace.jpg"
            descriptor="My current project in Unreal Engine 4, programmed in C++. Features Network synced bots with ground to space combat.
            Currently working on increasing bot counts and behaviour capabilities."
          >
          </ImageCard>
        </HeroWrapper>
      </Hero>
      <Projects>
      <Title>Projects</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="AlbertaSat"
            link="https://albertasat.ca/"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            <h2>September 2017 - January 2018</h2>
            Worked with the AlbertaSat team to implement 3D graphical visualizations of the real time position 
            of their satellite 'Ex-Alta 1' on their website using THREE.js.
          </ProjectCard>
          <ProjectCard
            title="Facebook Global Hackathon"
            link="https://www.facebook.com/hackathon/videos/10154882492730988/?t=564"
            bg="linear-gradient(to right, #00AAEE 0%, #35EE21 100%)"
          >
            <h2>November 15 - 18 2017</h2>
            <h3>Top 5 Finalist (Team Canada)</h3>
            Created a iPhone and Android offline peer to peer Mesh Network framework to integrate with online APIs.
            We demonstrated its capabilities with a simulated offline Facebook Safety check scenario.
          </ProjectCard>
          <ProjectCard
            title="Hack The North Canada's Largest Hackathon"
            link="https://devpost.com/software/m3sh"
            bg="linear-gradient(to right, #00AAEE 0%, #35EE21 100%)"
          >
            <h2>September 15 - 17 2017</h2>
            <h3>FB API Prize Winner</h3>
            Modelled geospatial data gathered from a mesh network to create 
            interactive 3D models for analysts and first responders in disaster zones.
            <br />
            Demonstrated using a custom made physical 3D Hologram.
          </ProjectCard>
        </ProjectsWrapper>
      <Title>Work Experience</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="Reviewboard"
            link="https://www.youtube.com/watch?v=Xo6f9T02W5w"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            <h2>September 2018 - Present</h2>
            I worked as a Capstone student contributing to Mozilla's Review Board as 
            part of the Undergraduate Capstone Open Source Project(UCOSP) program.
            <br /><br />
            I used JavaScript, Python, Django, HTML and CSS to implement new features to the Review Board experience.
          </ProjectCard>
          <ProjectCard
            title="CARV Hair"
            link="https://carvhair.com/"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            <h2>July 2018 - September 2018</h2>
            Using Netlify, Gatsby, React and GraphQL I developed and hosted a static website for CARV Hair Team.
            <br /><br />
            Featuring integrated Mindbody Salon's widget booking system and complete content management system.
          </ProjectCard>
          <ProjectCard
            title="vrCave"
            link="https://www.vrcave.ca/"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
            <h2>January 2018 - August 2018</h2>
            Helped develop the virtual escape rooms Depth of Osiris and Dragon Tower as well as developing new 
            revamped animations and visual effects for the virtual arcade experience Hospital Of Horror.
          </ProjectCard>
          <ProjectCard
            title="AlbertaSat"
            link="https://albertasat.ca/"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            <h2>September 2017 - January 2018</h2>
            Worked with the AlbertaSat team to implement 3D graphical visualizations of the real time position 
            of their satellite 'Ex-Alta 1' on their website using THREE.js.
          </ProjectCard>
          <ProjectCard
            title="Facebook Global Hackathon"
            link="https://www.facebook.com/hackathon/videos/10154882492730988/?t=564"
            bg="linear-gradient(to right, #00AAEE 0%, #35EE21 100%)"
          >
            <h2>November 15 - 18 2017</h2>
            <h3>Top 5 Finalist (Team Canada)</h3>
            Created a iPhone and Android offline peer to peer Mesh Network framework to integrate with online APIs.
            We demonstrated its capabilities with a simulated offline Facebook Safety check scenario.
          </ProjectCard>
          <ProjectCard
            title="Hack The North Canada's Largest Hackathon"
            link="https://devpost.com/software/m3sh"
            bg="linear-gradient(to right, #00AAEE 0%, #35EE21 100%)"
          >
            <h2>September 15 - 17 2017</h2>
            <h3>FB API Prize Winner</h3>
            Modelled geospatial data gathered from a mesh network to create 
            interactive 3D models for analysts and first responders in disaster zones.
            <br />
            Demonstrated using a custom made physical 3D Hologram.
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Colin Kushniruk" />
          <AboutSub>
            I am a C++ Games Programmer with extensive experience in Unreal Engine 4.
            I also enjoy working on the other aspects of Game Development such as Asset and Level Design.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          Education: University of Alberta - 3rd year Computing Science
          <br />
          Specialties:<br />
          C++ Programming<br />
          AI Behaviour in Games<br />
          Design
        </AboutDesc>
      </About>
      <Contact>
        <Inner>
          <Title>Contact Me</Title>
          <ContactText>
            Find me on <a href="https://www.linkedin.com/in/colin-kushniruk/">LinkedIn</a> or other platforms:{' '}
            <a href="https://devpost.com/ColinDaniel">DevPost</a> &{' '}
            <a href="https://github.com/cdkushni">GitHub</a>
          </ContactText>
        </Inner>
        <Footer>
          ColinKushniruk &copy; 2018
        </Footer>
      </Contact>
    </Parallax>
          </div>
        </div>
    </div>
  </div>
  </>
)

export default Index
