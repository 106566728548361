import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Spring, animated, config } from 'react-spring'

const CardItem = styled.div`
  left: 0%;
  width: 40vw;
  height: 45vh;
  min-height: 500px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: rgba(25, 50, 50, 0.3);
  transition: all 0.3s ease-in-out;

  &:hover {
    color: white;
    transform: translateY(-6px);
  }

  @media (max-width: 1920px) {
    min-height: 300px;
  }
`

const Cover = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: absolute;
  div {
    overflow: hidden;
  }
`

const Content = styled.div`
  padding: 1rem;
  position: relative;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  opacity: 0;
  background: rgba(200, 155, 75, 0.3);
  height: 0;

  ${CardItem}:hover & {
    opacity: 1;
    height: 120px;
  }
`

const Bottom = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  div:first-child {
    margin-right: 1rem;
  }
`

const Name = styled.h2`
  margin-bottom: 0;
  margin-top: 0;
`

const ImageCard = ({ path, cover, title, descriptor }) => (
  <Spring
    native
    delay={200 * 1}
    from={{ opacity: 0, transform: 'translate3d(0, 60px, 0)', left: '30%', position: 'absolute' }}
    to={{ opacity: 1, transform: 'translate3d(0, 0, 0)', left: '30%', position: 'absolute' }}
    config={config.slow}
  >
    {props => (
      <animated.div style={props}>
        <a href={path}>
        <CardItem>
          <Cover>
            <img src={cover} />
          </Cover>
          <Content>
            <Name>{title}</Name>
            <Bottom>
              <div>{descriptor?descriptor:""}</div>
            </Bottom>
          </Content>
        </CardItem>
        </a>
      </animated.div>
    )}
  </Spring>
)

export default ImageCard

ImageCard.propTypes = {
  path: PropTypes.string.isRequired,
  cover: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  descriptor: PropTypes.string,
}
